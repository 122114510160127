import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import styles from "./drumkit.module.css";

const sounds = {
  w: "/assets/sounds/tom-1.mp3",
  a: "/assets/sounds/tom-2.mp3",
  s: "/assets/sounds/tom-3.mp3",
  d: "/assets/sounds/tom-4.mp3",
  j: "/assets/sounds/snare.mp3",
  l: "/assets/sounds/crash.mp3",
  k: "/assets/sounds/kick-bass.mp3",
};

const DrumKit = () => {
  const [buttons] = useState(["w", "a", "s", "d", "j", "l", "k"]);
  const [pressedButton, setPressedButton] = useState("");

  useEffect(() => {
    const handleKeyDown = (event) => {
      makeSound(event.key);
    };
    document.addEventListener("keydown", handleKeyDown);

    return () => document.removeEventListener("keydown", handleKeyDown);
  }, []);

  const makeSound = (key) => {
    if (sounds.hasOwnProperty(key)) {
      const audio = new Audio(sounds[key]);
      audio.play();
    }
  };

  const buttonAnimation = (button) => {
    setPressedButton(button);
    setTimeout(() => setPressedButton(""), 100);
  };

  return (
    <div className={styles.drumKit}>
      <Container className={styles.drumContainer}>
        {buttons.map((button) => (
          <button
            key={button}
            className={`${styles.drum}  ${styles[button]} ${
              pressedButton === button ? styles.pressed : ""
            }`}
            onClick={() => {
              makeSound(button);
              buttonAnimation(button);
            }}
          >
            {button}
          </button>
        ))}
      </Container>
    </div>
  );
};

export default DrumKit;
