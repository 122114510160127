const experiences = [
    {
        _id: 1,
        role: "Developer",
        organisation: "Te Tāhuhu o te Mātauranga (MoE)",
        image: "https://www.education.govt.nz/_next/static/media/te-tahuhu-logo-purple.22b79467.svg",
        years: "Present",
        url: "https://www.education.govt.nz/",
        description: "In the Web Practice team, I collaborate with developers to add and improve UI features on education.govt.nz website using Docker containers, Drupal CMS paired with SQL db, PHP modules, NextJS and Git version control. Adding enhancements such as a \"latest news\" side panel, increase character input using PHP module, applying hover-focus-active and fixing SCSS styling for components. Collaborate with cross-functional teams to deliver user-centric solutions using agile methodologies."
    },
    {
        _id: 2,
        role: "Software Developer",
        organisation: "Te Tari Taiwhenua (DIA)",
        years: "2024",
        url: "https://www.dia.govt.nz/",
        description: "In the Digital Delivery & Group Operations, Development and Testing Services team, I contributed to the development, maintenance, and improvement of critical government digital platforms like the Gazette.govt.nz, and Govt.nz websites. Helping enhance the accessibility and mobile friendliness of the Gazette “advanced search” SCSS styling, improving accessibility and UX for our web visitors and users. I participated in Agile ceremonies, using the team's Jira board to manage our sprints and played a key role in resolving some technical issues."
    },
    {
        _id: 3,
        role: "Graduate Developer",
        organisation: "WorkSafe NZ",
        years: "2023",
        url: "https://www.worksafe.govt.nz/",
        description: "Within the Intervention Management System (IMS) in the Digital Transformation team, I worked with PowerApps, to implement changes and substituting business rules with JavaScript functions uploaded to PowerApps as web resources in the library. Collaborated with Dynamics365 and ADO to enhance Atlas, WorkSafe's incident notification system, by updating JS snippets for Portal and adjusted submission forms in PowerApps. Participated in UAT sessions to enhance UX for Portal customers and the Agency’s triage team."
    },
    {
        _id: 4,
        role: "ITSM Intern",
        organisation: "Sentify Wellington",
        years: "2022",
        url: "https://sentify.co/",
        description: "Delivered service support for international clients, conducting repository retrievals and NPM packages updates, while gaining exposure to AWS services such as EC2 and Route53, as well as Pulumi and a brief introduction to Google Cloud Platform. Additionally, I ensured our clients Confluence documentation pages were up-to-date, facilitating smooth transitions for future developers they would hired."
    },
    {
        _id: 5,
        role: "Company Representative",
        organisation: "Otaika Valley Free-Range Eggs",
        years: "2019",
        url: "https://otaikavalleyeggs.co.nz/",
        description: "Looked after a portfolio of stores in Wellington region, checked inventories in-store, rotate stocks following the First In First Out rule. Organised emergency orders with managers and check ETAs."
    },
    {
        _id: 6,
        role: "Merchandiser (Summer break)",
        organisation: "Otaika Valley Free-Range Eggs",
        years: "2017",
        url: "https://otaikavalleyeggs.co.nz/",
        description: "Managed product displays, placement and stock levels in-store. Implemented merchandising strategies to increase sales. Built relationships with store managers and staff."
    },
    {
        _id: 7,
        role: "Front of House",
        organisation: "La Cloche Café (Kaiwharawhara)",
        years: "2016",
        url: "https://www.lacloche.co.nz/",
        description: "Provided excellent customer service. Prepared and served food and beverages. Worked as part of a team to maintain a clean and efficient hospitality environment."
    },
];

export default experiences;
