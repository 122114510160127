import React from "react";
import { Container, Image } from "react-bootstrap";
import styles from "../components/hobbies.module.css";
import DrumKit from "./projects/DrumKit";

const Hobbies = () => {
  const handleClick = () => {
    const link = document.createElement("a");
    link.href = process.env.PUBLIC_URL + "/assets/files/quiz.apk"; // Use PUBLIC_URL
    link.download = "quiz.apk";
    link.click();
  };

  return (
    <>
      <h1 className={styles.mainTitle}>My hobbies</h1>
      <Container className={styles.hobbiesContainer}>
        <p className={styles.hobbiesTitleContainer}>
          I love to cook and bake, learn new languages and technologies, take
          photographs and edit videos, and visit new places.
          <br /> I'm always looking for new experiences and challenges, and I
          love to share my hobbies with others.
          <br />
        </p>
        <div className={styles.bodyContainer}>
          <h2>I enjoy</h2>
          <ul>
            <li>Cooking and baking </li>
            <li>Learning new languages and technologies </li>
            <li>Photography and video editing</li> <li>Visiting new places</li>
          </ul>
        </div>
        <div className={styles.projectsTitleContainer}>
          <h2>Dev lab project</h2>
          <div className={styles.projectsContainer}>
            <h3>Bookatour</h3>
            <p>React and Bootstrap e-commerce</p>
            <a
              href="https://eshop-cveu.vercel.app/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                src="/assets/thumbnails/tours-thumbnail.jpg"
                alt="e-commerce"
                roundedCircle
              ></Image>
            </a>
          </div>
          <div className={styles.projectsContainer}>
            <h3>Blog</h3>
            <p>HTML, CSS and 11ty blog with CMS</p>
            <a
              href="https://tikitips.netlify.app/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                src="/assets/thumbnails/blog-thumbnail.jpg"
                alt="11ty-blog"
                roundedCircle
              ></Image>
            </a>
          </div>
          <div className={styles.projectsContainer}>
            <h3>PHPGames</h3>
            <p>A simple PHP guessing game</p>
            <a
              href="https://hangman-php.vercel.app/api/index.php"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                src="/assets/thumbnails/php-games-thumbnail.jpg"
                alt="php-project"
                roundedCircle
              ></Image>
            </a>
          </div>
          <div className={styles.projectsContainer}>
            <h3>Drum Kit</h3>
            <p>Simple HTML & CSS drum kit</p>
            <DrumKit />
          </div>
          <div className={styles.projectsContainer}>
            <div>
              <h3>KnowTheFlags</h3>
              <p>A simple Android quiz app built with Android Studio</p>
            </div>
            <div className={styles.thumbnailContainer}>
              <Image
                src="/assets/thumbnails/quizz-app-thumbnail.jpg"
                alt="android quiz app"
                rounded
              ></Image>
              <button
                onClick={handleClick}
                type="button"
                class="btn btn-success"
              >
                Download for Android
              </button>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Hobbies;
